.welcomeText {
  display: flex;
  align-items: center;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}

.secondContainer {
  display: flex;
  justify-content: space-between;
}

.text {
  position: absolute;
  color: white;
  font-size: 18px;
}

/* Position the text based on conditions */
.top-left {
  top: 10px;
  left: 10px;
}

.top-right {
  top: 10px;
  right: 10px;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-left {
  bottom: 10px;
  left: 10px;
}

.bottom-right {
  bottom: 10px;
  right: 10px;
}
