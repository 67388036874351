*,
*::before,
*::after {
    padding: 0;
    margin: 0
}


.ProseMirror {
    padding: 16px;
    min-height: 128px
}

.tiptap {
    :first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        text-wrap: pretty;
    }


    h1 {
        margin-bottom: 12px
    }

    h2 {
        margin-bottom: 10px
    }

    h3 {
        margin-bottom: 10px
    }

    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 8px
    }

    /* Code and preformatted text styles */
    code {
        background-color: var(--purple-light);
        border-radius: 0.4rem;
        color: var(--black);
        font-size: 0.85rem;
        padding: 0.25em 0.3em;
    }

    pre {
        background: var(--black);
        border-radius: 0.5rem;
        color: var(--white);
        font-family: "JetBrainsMono", monospace;
        margin: 1.5rem 0;
        padding: 0.75rem 1rem;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
        }
    }

    blockquote {
        border-left: 3px solid var(--gray-3);
        margin: 1.5rem 0;
        padding-left: 1rem;
    }

    hr {
        border: none;
        border-top: 1px solid #7e7e7e;
        margin: 2rem 0;
    }

    .ProseMirror-focused {
        outline: "none";
    }

    .editor-empty::before {
        content: attr(data-placeholder);
        color: #7e7e7e;
        position: absolute;
        pointer-events: none;
    }

    img {
        display: block;
        height: auto;
        margin: 1.5rem 0;
        width: 100%;
        max-height: 400px;
        object-fit: cover;

        &.ProseMirror-selectednode {
            outline: 3px solid var(--purple);
        }
    }

}
