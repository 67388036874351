::-webkit-scrollbar {
    width: 8px;
    overflow-y: scroll;
    background: rgba(112,112,112,0.4);
    box-shadow: inset 0 0 4px rgba(112,112,112,0.4);
}

::-webkit-scrollbar-thumb {
    background: rgba(112,112,112,1);
    border-radius: 8px;
}