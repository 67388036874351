.headerTypo {
  flex: 1 !important ;
  justify-content: center !important ;
  align-items: center !important ;
  text-overflow: ellipsis !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.cardContentStyle {
  height: 147px !important ;
  min-height: 147px !important ;
  max-height: 160px !important ;
  border-radius: 20px !important ;
  border: none !important ;
  width: 100% !important ;
  max-width: 310px !important ;
  background-size: cover !important ;
}

.expiryTypo {
  font-size: 10px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.hniCountStyle {
  font-size: 10px !important ;
  color: white !important ;
  padding: 2px !important ;
  text-decoration: underline !important ;
}

.productDescriptions {
  font-family: "Mona Sans", sans-serif !important ;
  font-size: 14px !important ;
  text-transform: uppercase !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.productSpan {
  font-family: "Mona Sans", sans-serif !important ;
  font-size: 12px !important ;
  letter-spacing: 0.41px !important ;
  color: #e8e8e8 !important ;
}

.minInvestmentCSS {
  font-size: 10px !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  letter-spacing: 0.34px !important ;
  color: #e8e8e8 !important ;
  overflow: hidden !important ;
}

.usDollar {
  font-size: 10px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  letter-spacing: 0.34px !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.viewButton {
  background: #6442c4 0% 0% no-repeat padding-box !important ;
  opacity: 1 !important ;
  color: #ffffff !important ;
  justify-content: center !important ;
  font-family: "Mona Sans", sans-serif !important ;
  border-radius: 6px !important ;
  font-size: 12px !important ;
  font-weight: 600 !important ;
  height: 50% !important ;
  margin-top: 12px !important ;
}
.viewButton:hover {
  background: #6442c4 0% 0% no-repeat padding-box !important ;
  opacity: 1 !important ;
  color: #ffffff !important ;
}

.nodataMessage {
  padding: 45px !important ;
  margin: 10 !important ;
  font-family: "Mona Sans", sans-serif !important ;
}

.generalCardCSS {
  font-family: "Mona Sans", sans-serif !important ;
  text-align: "left" !important ;

  letter-spacing: 0px !important ;
  color: #333333 !important ;
  opacity: 1 !important ;
}

/* .gridStyles {
  padding: 5px!important ;
  border-radius: 4px!important ;
  margin: 5px!important ;
  box-shadow: 0px 3px 6px #86868633!important ;
  border: 1px solid #e9e9e9!important ;
  opacity: 1!important ;
} */

.gridinnerStyle {
  border-radius: 4px !important ;
  padding-right: 10px !important ;
  min-height: 75px !important ;
  max-height: 233px !important ;
  padding-top: 10px !important ;
  padding-left: 19px !important ;
  padding-bottom: 14px !important ;
  width: 100% !important ;
}

.ProductServiceHeaderStyle {
  font-size: 14px !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.productDetailsStyle {
  white-space: nowrap !important ;
  overflow: hidden !important ;
  font-size: 14px !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
}

.generalVie {
  background-color: #000000 !important ;
  box-shadow: 1px 8px 29px #695f9724 !important ;
  color: white !important ;
  border-radius: 8px !important ;
}
.generalVie:hover {
  background-color: #000000 !important ;
  box-shadow: none !important ;
}

.listTypoGRaphyCateogory {
  flex: 1 !important ;
  justify-content: center !important ;
  align-items: center !important ;
}

.listGridStyle {
  margin-top: 5px !important ;
  border-radius: 8px !important ;
}
.listGridStyles {
  padding: 5px !important ;
  min-height: 99px !important ;
  width: 100% !important ;
  border-radius: 6px 0px 0px 6px !important ;
  background-size: cover !important ;
}
.listTypoStyle {
  letter-spacing: 0.54px !important ;
  color: #000000 !important ;
  text-transform: uppercase !important ;
  opacity: 1 !important ;
  padding-top: 28px !important ;
  padding-left: 20px !important ;
  font-size: 14px !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.listTypoDescriptions {
  padding-left: 20px !important ;
  padding-top: -5px !important ;
  font-size: 12px !important ;
  color: #4b4b4b !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
  letter-spacing: 0.48px !important ;
  opacity: 1 !important ;
}

.listWebStyles {
  /* padding-left: 15px!important ;
  padding-right: 5px!important ; */
  border-radius: 0px 8px 8px 0px !important ;
  align-items: center !important ;
  /* padding-top: 28px!important ;
  padding-bottom: 20px!important ; */
  min-height: 99px !important ;
  background-size: cover !important ;
  background-color: #000000 !important ;
  justify-content: space-between !important ;
}

.listExpiry {
  font-size: 12px !important ;
  color: #e8e8e8 !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.listDates {
  font-size: 10px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  letter-spacing: 0.37px !important ;
  opacity: 1 !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.minimumInvestedHeaderStyle {
  font-size: 10px !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
  letter-spacing: 0.41px !important ;
  color: #e8e8e8 !important ;
  opacity: 1 !important ;
}

.hnniInvested {
  font-size: 10px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.listAvatorStyles {
  font-size: 10px !important ;
  color: #ffffff !important ;
  padding-bottom: 5px !important ;
  text-decoration: underline !important ;
  font-family: "Mona Sans", sans-serif !important ;
}

.listViewButton {
  background: #5626ce 0% 0% no-repeat padding-box !important ;
  opacity: 1 !important ;
  color: #ffffff !important ;
  font-family: "Mona Sans", sans-serif !important ;
  border-radius: 6px !important ;
  font-size: 12px !important ;
  font-weight: 600 !important ;
  height: 90% !important ;
}
.listViewButton:hover {
  background: #5626ce 0% 0% no-repeat padding-box !important ;
  opacity: 1 !important ;
  color: #ffffff !important ;
}

.listMobileStyles {
  border-radius: 25px !important ;
  margin-top: 5px !important ;
}

.gridSTylesMobile {
  padding: 5px !important ;
  height: 30px !important ;
  width: 100% !important ;
  border-radius: 18px 18px 0px 0px !important ;
  border: 0px solid lightgray !important ;
  background-size: "cover" !important ;
}

.listExpiryMobile {
  color: "black" !important ;
  padding-left: 15px !important ;
  font-size: 11px !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.innerGridListStyle {
  padding-left: 15px !important ;
  padding-right: 5px !important ;
  padding-top: 23px !important ;
  padding-bottom: 10px !important ;
  min-height: 99px !important ;
  border-radius: 0px 0px 18px 18px !important ;
  background-size: cover !important ;
  background-repeat: no-repeat !important ;
  justify-content: space-between !important ;
}

.mobileProductStyle {
  margin-top: 12px !important ;
  letter-spacing: 0.48px !important ;
  text-transform: uppercase !important ;
  opacity: 1 !important ;
  font-size: 14px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
}

.mobileProductDetails {
  font-size: 12px !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  overflow: hidden !important ;
  letter-spacing: 0.41px !important ;
  color: #e8e8e8 !important ;
  opacity: 1 !important ;
}

.mobileMinimumInvested {
  font-size: 10px !important ;
  text-overflow: ellipsis !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  padding-top: 28px !important ;
  overflow: hidden !important ;
  letter-spacing: 0.34px !important ;
  color: #e8e8e8 !important ;
  opacity: 1 !important ;
}

.mobileDollor {
  font-size: 10px !important ;
  color: #ffffff !important ;
  text-overflow: ellipsis !important ;
  font-weight: 600 !important ;
  font-family: "Mona Sans", sans-serif !important ;
  white-space: nowrap !important ;
  letter-spacing: 0.34px !important ;
  opacity: 1 !important ;
  overflow: hidden !important ;
}

.viewMobileButtonStyle {
  background: #6442c4 0% 0% no-repeat padding-box !important ;
  float: right !important ;
  letter-spacing: 0.41px !important ;
  opacity: 1 !important ;
  color: #ffffff !important ;
  font-family: "Mona Sans", sans-serif !important ;
  border-radius: 6px !important ;
  font-size: 12px !important ;
  font-weight: 600 !important ;
  height: 75% !important ;
}
.viewMobileButtonStyle:hover {
  background: #6442c4 0% 0% no-repeat padding-box !important ;

  color: #ffffff !important ;
}
